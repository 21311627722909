import { installationManagementRoutes } from './modules/installation-management';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Vue from 'vue';
import VueRouter, { RawLocation, RouteConfig } from 'vue-router';
import Layout from '@/layout/layout.vue';
import { ErrorHandler, Position, PositionResult, Route } from 'vue-router/types/router';
import { systemSettingRoutes } from './modules/system-setting';
import { organizationRoutes } from './modules/organization';
import { productManagementRoutes } from './modules/product-management';
import { databaseRoutes } from './modules/database';
import { craftManagementRoutes } from './modules/craft-management';
import { customerManagementRoutes } from './modules/customer-management';
import { supplierManagementRoutes } from './modules/supplier-management';
import { PermissionRoute } from '@/resource/model';
import { ResourceStatusEnum } from '@/resource/enum';
import { projectManagementRoutes } from './modules/project-management';
import { productionManagementRoutes } from './modules/production-management';
import { prepressTaskRoutes } from './modules/prepress-task';
import { logisticsManagementRoutes } from './modules/logistics-management';
import { financeRoutes } from './modules/finance';
import { cloneDeep } from 'lodash-es';
import { hmModuleRoutes } from './modules/hm-module';
import { publicDataRoutes } from './modules/public-data';
import { feedbackSuggestionRoutes } from './modules/feedback-suggestion';
import { taskCenterRoutes } from './modules/task-center';
import { prepressStatisticsRoutes } from './modules/prepress-statistics';

Vue.use(VueRouter);

// 解决由于路由版本引起的，router.push或router.replace时报错
const originalPush = VueRouter.prototype.push as any;
VueRouter.prototype.push = function push(
  location: RawLocation,
  onComplete?: Function | undefined,
  onAbort?: ErrorHandler | undefined
) {
  if (onComplete || onAbort) {
    return originalPush.call(this, location, onComplete, onAbort);
  }
  return originalPush.call(this, location).catch((error: any) => {
    if (VueRouter.isNavigationFailure(error)) {
      // resolve err
      return error;
    }
    // rethrow error
    return Promise.reject(error);
  });
};

/*
  redirect:                      如果设置为'noredirect'，点击面包屑时不会触发重定向操作
  meta: {
    title: 'title'               显示在subMenu和breadcrumb中的名称 (推荐设置)
    icon: '#svg-name'             the icon showed in the sidebar
    breadcrumb: false            如果为false，该项将隐藏在面包屑中(默认为true)
    hidden: true                 如果为true，此路由将不会显示在侧边栏中(默认为false)
  }
*/
/**
 * 常量路由
 */
export const constantRoutes: Array<RouteConfig> = [
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import(/* webpackChunkName: "redirect" */ '@/views/redirect/index.vue')
      }
    ]
  },
  {
    path: '/login',
    component: async () => import(/* webpackChunkName: "login" */ '@/views/login/login.vue'),
    meta: {
      title: 'route.login',
      hidden: true
    }
  },
  {
    path: '/404',
    component: async () => import(/* webpackChunkName: "404" */ '@/views/error-page/404/404.vue'),
    meta: { hidden: true }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    name: 'dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: async () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/dashboard.vue'),
        meta: {
          title: 'route.dashboard',
          icon: '#dashboard',
          affix: true
        }
      }
    ]
  },
  {
    path: '/mine',
    component: Layout,
    name: 'Mine',
    children: [
      {
        path: 'account-setting',
        name: 'AccountSetting',
        component: async () =>
          import(/* webpackChunkName: "account-setting" */ '@/views/mine/account-setting/account-setting.vue'),
        meta: {
          title: 'route.personalSetting',
          hidden: true
        }
      },
      {
        path: 'message-center',
        name: 'MessageCenter',
        component: async () =>
          import(/* webpackChunkName: "message-center" */ '@/views/mine/message-center/message-center.vue'),
        meta: {
          title: 'route.messageCenter',
          hidden: true,
          noCache: true
        }
      }
    ],
    meta: {
      hidden: true
    }
  }
];

export const dynamicRoutes: Array<RouteConfig> = [
  systemSettingRoutes,
  organizationRoutes,
  productManagementRoutes,
  databaseRoutes,
  craftManagementRoutes,
  customerManagementRoutes,
  supplierManagementRoutes,
  projectManagementRoutes,
  productionManagementRoutes,
  prepressTaskRoutes,
  logisticsManagementRoutes,
  installationManagementRoutes,
  financeRoutes,
  hmModuleRoutes,
  publicDataRoutes,
  feedbackSuggestionRoutes,
  taskCenterRoutes,
  prepressStatisticsRoutes
];

/**
 * 基于前端配置的路由表和登录角色可访问路由表，合并一些动态配置的属性
 * @param dynamicRoutesConfig 前端配置的动态路由表
 * @param permissionRoutes 当前登录角色可访问的路由
 * @returns 合并后的路由表
 */
export const mergeRouteConfigs = (
  dynamicRoutesConfig: Array<RouteConfig>,
  permissionRoutes: Array<PermissionRoute>
): Array<RouteConfig> => {
  const mergedRoutes: RouteConfig[] = [];
  permissionRoutes.sort((a, b) => a.sort - b.sort);
  // eslint-disable-next-line no-param-reassign
  const allUsingRoutes = permissionRoutes.filter(x => x.status === ResourceStatusEnum.using);
  for (const permissionRoute of allUsingRoutes) {
    const dynamicRoute = cloneDeep(dynamicRoutesConfig.find(x => x.path === permissionRoute.url));
    const regex = /^(https?:\/\/)/;
    if (regex.test(permissionRoute.url)) {
      // 添加外链路由
      const externalLinkRoutes: RouteConfig = handeExternalLink([permissionRoute])[0];
      mergedRoutes.push(externalLinkRoutes);
    }
    if (!dynamicRoute) {
      continue;
    }
    dynamicRoute.meta!.sort = permissionRoute.sort;
    if (dynamicRoute.children && dynamicRoute.children.length > 0) {
      dynamicRoute.children = mergeRouteConfigs(dynamicRoute.children, permissionRoute.children);
    }
    mergedRoutes.push(dynamicRoute);
  }
  return mergedRoutes;
};

// 外链路由处理
const handeExternalLink = (currentRoutes: Array<any>): Array<RouteConfig> => {
  const mergedRoutes: RouteConfig[] = [];
  for (const route of currentRoutes) {
    const linkRoute = {
      path: `${route.url}`,
      name: `${route.name}`,
      component: async () => import(/* webpackChunkName: "customer" */ '@/views/outer-chain/outer-chain.vue'),
      meta: {
        title: `${route.name}`,
        outerChainPath: `${route.path}`,
        icon: `${route.icon}`
      },
      children: route.children.length > 0 ? [] : null
    } as RouteConfig;
    if (route.children && route.children.length > 0) {
      linkRoute.children = handeExternalLink(route.children);
    }
    mergedRoutes.push(linkRoute);
  }
  return mergedRoutes;
};

const createRouter = (): VueRouter =>
  new VueRouter({
    mode: 'history', // Disabled due to Github Pages doesn't support this, enable this if you need.
    scrollBehavior: (
      to: Route,
      from: Route,
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      savedPosition: Position | void
    ): PositionResult | Promise<PositionResult> | undefined | null => {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    base: String(process.env.BASE_URL),
    routes: constantRoutes
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter(): void {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
