/**
 * 产品明细项次状态
 */
export enum ProjectProductStatusEnum {
  /**
   * 耗损
   */
  consumed = -1,
  /**
   * 新建
   */
  new = 1,
  /**
   * 待生产
   */
  waitForProduction,
  /**
   * 生产中
   */
  inProduction,
  /**
   * 待确认
   */
  waitConfirm,
  /**
   * 已完成
   */
  completed
}

/**
 * 产品制作状态
 */
export enum ProjectProductMakeStatusEnum {
  /**
   * 未派单
   */
  undistributed = 1,
  /**
   * 已派单
   */
  distributed = 2,
  /**
   * 已制作
   */
  made = 3
}